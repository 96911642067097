import React, { useContext, useEffect } from 'react';
import { Helmet } from 'rnd-helmet';
import { injectIntl } from "react-intl";
import { useDataLayer } from "dataLayerHelper";
import FormContext from "formContext";
import axios from "axios";
import GetRouteByName from 'getRouteByName';
import List from "react-content-loader";
import Link from "@Components/Link";
import JobCardList from "@Components/JobCardList";
import NoAuthBlockDescription from "@Components/NoAuthBlockDescription";
import SubMenu from "@Components/SubMenu";
import Icon from "@Components/Icon";

function Favourites({intl, staticContext, match}) {
  const { additionalData, setAdditionalData, isAuthenticated, timesRendered } = useContext(FormContext);
  const { locale } = intl;

  // Generate urls from internal routes.
  const registerPageUrl = GetRouteByName('register').url;
  const accountPageUrl = GetRouteByName('account') ? GetRouteByName('account').url : undefined;
  const jobsPageUrl = GetRouteByName('jobs').url;

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext, "favouriteJobs");

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      if (isAuthenticated) {
        getFromServer();
      }
    }
    if (!isAuthenticated) {
      getFromLocalStorage();
    }
  }, [timesRendered]);

  const showMoreVisibility = !!isAuthenticated;
  const offsetPerPage = isAuthenticated ? 15 : 10;

  const favouriteJobsList = additionalData.favouriteJobs;
  const count = (typeof favouriteJobsList !== 'undefined') ? favouriteJobsList.count : 0;

  let noticeType = "warning"
  let noticeMessage = '';
  let blockAlternativeLabel = "";

  let allowDefaultCard = false;

  if (count < 9 && count > 0) {
    allowDefaultCard = true;
  }

  // Handle notice behavior and job counters.
  const diff = offsetPerPage - Number(count);

  if (count > 0 ) {
    noticeMessage = intl.formatMessage({id: 'Job.SpotsLeft.Title'}, { u: diff, v: registerPageUrl });
    blockAlternativeLabel = intl.formatMessage({id: 'Job.SpotsLeft'}, { u: diff });
  }
  else {
    noticeMessage = intl.formatMessage({id: 'Job.SpotsMessage'}, { u: accountPageUrl });
    blockAlternativeLabel = intl.formatMessage({id: 'Job.NoSpotsLeft'});
  }

  // Gets favourite jobs from server
  const getFromServer = () => {
    axios.get(`${process.env.REACT_APP_API_PREFIX}/user/saved-jobs`)
      .then(response => {
        setAdditionalData(prevState => ({
          ...prevState,
          ...response.data.jobs
        }));
        updateLocalStorage(response.data.jobs);
      });
  }

  // Gets favourite jobs from local storage
  const getFromLocalStorage = () => {
    const jobs = JSON.parse(localStorage.getItem("favJobs")) ? JSON.parse(localStorage.getItem("favJobs")) : {};
    const jobToArray = Object.keys(jobs).map(key => {
      return jobs[key];
    });
    const count = jobToArray.length;

    setAdditionalData({'favouriteJobs': { jobs: jobToArray, count }});
  };

  // Updates local storage with favorite jobs list
  const updateLocalStorage = jobs => {
    if (jobs.length < 1) return;
    localStorage.setItem("favJobs", JSON.stringify(jobs));
  }

  if (favouriteJobsList && favouriteJobsList.hasOwnProperty('jobs')) {
    favouriteJobsList.jobs.forEach(favJob => {
      favJob.showDate = 'jobPostDate';
    });
  }

  const noAuthCounter = (count > 9) ? offsetPerPage : count;
  const displayedCount = isAuthenticated ? count : noAuthCounter;
  const JobsTitle = () => intl.formatMessage({id: 'Favourites.Section.Title'}, {u: displayedCount});

  const AccountBenefitsDescription = () => {
    return (
      <div className="banner banner--alternative">
        <div className="banner__wrapper wrapper">
          <div className="banner__media media-block">
            <img src="/myrandstad-app/assets/image/favorites/PencilWithLine.svg" alt="" />
          </div>
          <div className="banner__content content-block">
            <h2 className="content-block__title">
              {intl.formatMessage({id: 'Job.Account.Benefits.Title'})}
            </h2>
            <div className="content-block__description">
              <div className="body-copy">
                <ul className="list--checks">
                  <li>{intl.formatMessage({id: 'Job.Account.Benefits.ListItem1'})}</li>
                  <li>{intl.formatMessage({id: 'Job.Account.Benefits.ListItem2'})}</li>
                  <li>{intl.formatMessage({id: 'Job.Account.Benefits.ListItem3'})}</li>
                </ul>
              </div>
            </div>
            <Link to={registerPageUrl} className="button button--filled button--m">
              {intl.formatMessage({id: 'User.Account.Create'})}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const Notice = ({ text }) => {
    return (
      <div className="block bg-brand-primary-tint-7 notice-text-only">
        <div className="wrapper notice-text-only__wrapper">
          <div className="notice-text-only__content">
            <div className="notice-text-only__description">
              <div className="description">
                <p dangerouslySetInnerHTML={{ __html: text}}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const NoJobs = ({children}) => (
    <div className={`banner`}>
      <div className="banner__wrapper wrapper">
        <div className="banner__media media-block">
          {isAuthenticated && <img src="/myrandstad-app/assets/image/favorites/lock-in-heart.svg" alt="" />}
          {!isAuthenticated && <img className="pull-to-top" src="/myrandstad-app/assets/image/favorites/click_heart.svg" alt="" />}
        </div>
        <div className="banner__content content-block">
          <p className="content-block__eyebrow">{intl.formatMessage({id: 'Job.NoFavouriteJobs.Eyebrow'})}</p>
          <h2 className="content-block__title">
            {intl.formatMessage({id: 'Job.NoFavouriteJobs.Title'})}
          </h2>
          <p className="content-block__description">{children ? children : intl.formatMessage({id: 'Job.NoFavouriteJobs.Message'})}</p>
          <a href={jobsPageUrl} className="button button--m">
            {intl.formatMessage({id: 'Start.Search'})}
          </a>
        </div>
      </div>
    </div>
  );

  // Check if state is empty and show loader until ready.
  const isStateObjectEmpty = Object.keys(additionalData).length === 0 && additionalData.constructor === Object;

  const WelcomeBlockAnonymous = () => {
    return (
      <div>
        <Notice
          type={noticeType}
          text={noticeMessage}
          btnLabel={intl.formatMessage({id: 'User.Account.Create'})}
          btnUrl={registerPageUrl}
        />
        {
          (count > 0) ?
            (
              <div className="block block--s">
                <div className="block__wrapper block__wrapper--stacked wrapper">
                  <div className="block__header">
                    <h2 className="block__title">
                      {(typeof favouriteJobsList !== undefined) &&
                      (blockAlternativeLabel) && (
                        <div className="block__header block__header--split">
                          <h2 className="block__title">
                            <JobsTitle/>{' '}
                            {blockAlternativeLabel && (
                              <div className="tooltip tooltip--icon tooltip--right">
                                <span className="tooltip__trigger icon" tabIndex="0">
                                  <Icon type="info" className={null} />
                                  <span className="tooltip__content ">
                                    <span className="tooltip__text" dangerouslySetInnerHTML={{ __html: blockAlternativeLabel}}></span>
                                    <span className="tooltip__pointer"></span>
                                  </span>
                                </span>
                              </div>
                            )}
                          </h2>
                        </div>
                      )}
                    </h2>
                  </div>
                  <div className="block__content block__content--full-on-s block__content--full-on-m">
                    <JobCardList
                      showDefaultCard={allowDefaultCard}
                      offsetPerPage={offsetPerPage}
                      jobsPageUrl={jobsPageUrl}
                      {...favouriteJobsList} />
                  </div>
                </div>
              </div>
            ) :
            (
              <div className={`block block--xs bg-variant-white`}>
                <NoJobs>
                  <NoAuthBlockDescription/>
                </NoJobs>
              </div>
            )
        }
        { process.env.REACT_APP_COUNTRY !== 'no' && <AccountBenefitsDescription/> }
      </div>
    );
  }

  const WelcomeBlock = () => {
    // Show a loader until state is filled with data
    if (isStateObjectEmpty) {
      return (
        <div className="block block--s bg-brand--off-white">
          <div className="block__wrapper block__wrapper--stacked wrapper">
            <List uniqueKey="welcome-registered-user" />
          </div>
        </div>
      )
    }
    // If user has added fav jobs.
    if (count > 0) {
      return (
        <div className="block block--s bg-variant-white">
          <div className="block__wrapper block__wrapper--stacked wrapper">
            <div className="block__header block__header--l block__header--split">
              <h2 className="block__title">
                {(typeof favouriteJobsList !== undefined) && <JobsTitle/>}
              </h2>
            </div>

            <div className="block__content block__content--full-on-s block__content--full-on-m">
              <JobCardList
                showDefaultCard={allowDefaultCard}
                showMoreVisibility={showMoreVisibility}
                offsetPerPage={offsetPerPage}
                jobsPageUrl={jobsPageUrl}
                {...favouriteJobsList}/>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className={`block block--xs bg-variant-white`}>
          <NoJobs>
            {intl.formatMessage({id: 'Job.NoFavouriteJobs.Message'})}
          </NoJobs>
        </div>
      );
    }
  }

  return (
    <>
      {
        isAuthenticated ?
        <>
          <Helmet>
            <title>{intl.formatMessage({id: 'Favourites.Header.Title'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
            <header className={`header bg-variant-white header--s header--welcome`}/>
          </Helmet>
          <div className={`header bg-brand--white header--welcome`}>
            <SubMenu skipBreadcrumbs={true}/>
            <div className="header__wrapper wrapper">
            </div>
          </div>
          <WelcomeBlock/>
        </>
          :
          <>
          <Helmet>
            <title>{intl.formatMessage({id: 'Favourites.Header.Title'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
            <header className={`header bg-variant-brand-secondary header--s header--welcome`}/>
          </Helmet>
          <div className={`header bg-variant-brand-secondary header--welcome`}>
            <div className="header__wrapper wrapper">
              <SubMenu skipBreadcrumbs={true}/>
              <div className="header__content header__content--l content-block false">
              <h1 className="content-block__title">
                <span className="content-block__title-top">{intl.formatMessage({id: 'Favourites.Header.Title'})}</span>
              </h1>
              </div>
              <div className="header__media media-block">
                <img src="/myrandstad-app/assets/image/icons/PersonHeart_illustration.svg" alt=""/>
              </div>
            </div>
          </div>
          <div>
            {(isStateObjectEmpty) ?
              <div className="block block--s bg-brand--off-white">
                <div className="block__wrapper block__wrapper--stacked wrapper">
                  <List uniqueKey="welcome-anonymous-user"/>
                </div>
              </div>
              :
              <WelcomeBlockAnonymous/>
            }
          </div>
          </>
      }
    </>
  )
}

export default injectIntl(Favourites);
